import React from 'react'
import styled from 'styled-components'
import List from '../components/macos/List'
import ListEntry from '../components/macos/ListEntry'

function References({className}) {
    return (
        <div className={className}>
            <div className="page--header">
                References
            </div>
            <div className="text">
                I've been working with different popular programming languages for many years now. In the past few years, I've been specializing in Rust and Swift, but still using JavaScript and a few other languages a lot in my hobby projects, as well as in limited professional capacity.
            <br/><br/>
            </div>
            <section>
                <div className="section--header">
                    LinkedIn References
                </div>
                <List>
                    <ListEntry
                        title="Anthony Pässler"
                        description="I had the privilege of working with Marco at Compeon, his knowledge and technical acumen is incredible and he is always ready to put all his energy and time to get the job done. Additionally, Marco is very easy-going person and is always available to help out and to jump into a pair programming session. Marco certainly can get my full recommendation."
                    />
                    <ListEntry
                        title="Semih Han Ordu"
                        description="Probably Marco is one of the best developers I had the chance to meet. Not only did I learn a lot through our exchanges and close collaboration at Compeon, but I also had the opportunity to get to know him on a personal level. He is an open-minded, very intelligent and reflective person. His analytical skills, which allow him to solve complex issues and always look at problems from different angles, coupled with his gift to explain these issues well, make him a very valuable colleague. He has achieved a lot at a very young age, is a co-founder of his company, constantly appropriates new technologies and has always been very enthusiastic about his profession."
                    />
                    <ListEntry
                        title="Józef Pytko"
                        description="Marco is a talented and enthusiastic software developer who combines a solid theoretical knowledge with good technical skills. Being able to quickly pick up new technologies and to deliver clean, high quality solutions he was capable of solving complex problems. As a team member he was always happy to help and communicated proactively about obstacles whenever needed. It was great working with Marco, he will definitely be a valuable software developer in any team."
                    />
                    <ListEntry
                        title="Jann Schafranek"
                        description="Marco is an extraordinary software engineer knowing his way around basically every language. I couldn't be happier to work with him."
                    />
                    <ListEntry
                        title="Dominik Mack"
                        description="Marco is a very experienced full stack developer. Both on a professional and personal level, working with him has always been a very enjoyable experience. His skills in writing clean, well-functioning code are remarkable. The same can be said about his wide range of technologies, which he masters very well. In addition, he is always up-to-date regarding the developments of current programming languages. He processes his tasks quickly and conscientiously. I would want to work with him again at any time since he is not just a good colleague, but also a good friend of mine."
                    />
                    <ListEntry
                        title="Andrei Dimitriu"
                        description="A great full stack software developer. Marco thinks outside the box and always finds solutions to anything that gets in his way. He does an outstanding job of writing fast, optimized and elegant code. His work always reflects the best practices and fastest ways to get something done. Marco can work very well with everything from low level assembly to high level languages like JavaScript or Python. Always up to date on the latest developments. From new language releases to the best libraries to use for any task."
                    />
                    <ListEntry
                        title="Niklas Gadermann"
                        description="Marco is an amazing person to work with. He delivers high-quality results quickly and never misses out on the latest cutting-edge technologies. His knowledge of a wide variety of programming languages as well as his ability to quickly adopt new environments make him a flexible and reliable person. I would recommend him anytime."
                    />
                </List>
            </section>
            <section>
                <div className="section--header">
                    Programming Languages
                </div>
                <List>
                    <ListEntry
                        title="Rust"
                        description="Used in hobby projects since 2015, used professionally since 2022."
                    />
                    <ListEntry
                        title="JavaScript"
                        description="Used in hobby projects since 2010, used professionally since 2018."
                    />
                    <ListEntry
                        title="Swift"
                        description="Professionally since 2020 as part of Quintschaf."
                    />
                </List>
            </section>
            <section>
                <div className="section--header">
                    Socials
                </div>
                <div className="social-container">
                    <a className="linkedin" href="https://linkedin.com/in/marcoquinten/">in</a>
                    <a className="github" href="https://github.com/SplittyDev">gh</a>
                </div>
            </section>
        </div>
    )
}

export default styled(References)`
overflow: auto;

.page--header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.text {
    opacity: .9;
}

section {
    border-top: 1.5px solid hsla(0,0%,100%,.1);
    padding: 1rem 0;

    .section--header {
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }

    .social-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            width: 48px;
            height: 48px;
            border-radius: .25rem;
            box-shadow: 0 0 1rem hsla(0,0%,0%,.05);

            &.linkedin {
                background: hsla(220,75%,50%,.5);
            }

            &.github {
                background: hsla(0,0%,50%,.25);
            }
        }
    }
}
`
