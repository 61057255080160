import React from 'react'
import styled from 'styled-components'
import List from '../components/macos/List'
import ListEntry from '../components/macos/ListEntry'

import ImgThaiTutor from '../assets/gpts_thai_tutor.png'
import ImgGermanTutor from '../assets/gpts_german_tutor.png'

function GPTs({className}) {
    return (
        <div className={className}>
            <div className="page--header">
                My GPTs
            </div>
            <div className="text">
                I've created several GPTs which you might find helpful.<br />
                Keep in mind that using them requires a ChatGPT Plus subscription.<br/><br/>
            </div>
            <section>
                <div className="section--header">
                    Language Learning
                </div>
                <List>
                    <ListEntry
                        logo={ImgThaiTutor}
                        title="Thai Tutor"
                        description="A friendly Thai language tutor with a touch of humor."
                        action={{ name: 'Open in ChatGPT', href: 'https://chat.openai.com/g/g-7IpKjq6tJ-thai-tutor' }}
                    />
                    <ListEntry
                        logo={ImgGermanTutor}
                        title="German Tutor"
                        description="A friendly German language tutor with a touch of humor."
                        action={{ name: 'Open in ChatGPT', href: 'https://chat.openai.com/g/g-0sbzdkxgr-german-tutor' }}
                    />
                </List>
            </section>
            <section>
                <div className="section--header">
                    More to come
                </div>
                <List>
                    <ListEntry
                        title="Surprise Surprise"
                        description="Several other GPTs are in the works."
                    />
                </List>
            </section>
            <section>
                <div className="section--header">
                    Social
                </div>
                <div className="social-container">
                    <a className="linkedin" href="https://linkedin.com/in/marcoquinten/">in</a>
                    <a className="github" href="https://github.com/SplittyDev">gh</a>
                </div>
            </section>
        </div>
    )
}

export default styled(GPTs)`
overflow: auto;

.page--header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.text {
    opacity: .9;
}

section {
    border-top: 1.5px solid hsla(0,0%,100%,.1);
    padding: 1rem 0;

    .section--header {
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }

    .social-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            width: 48px;
            height: 48px;
            border-radius: .25rem;
            box-shadow: 0 0 1rem hsla(0,0%,0%,.05);

            &.linkedin {
                background: hsla(220,75%,50%,.5);
            }

            &.github {
                background: hsla(0,0%,50%,.25);
            }
        }
    }
}
`
