import React from 'react'
import styled from 'styled-components'

function Window({className, children}) {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export default styled(Window)`
display: flex;
flex-flow: row nowrap;
width: calc(100vw - 2rem);
height: calc(100vh - 2rem);
overflow: hidden;
box-shadow: 0 0 1rem hsla(0,0%,0%,.25);
user-select: none;
cursor: default;
-webkit-backdrop-filter: blur(2rem);
backdrop-filter: blur(2rem);

@media screen and (min-width: 900px) {
    width: 80vw;
    height: 90vh;
}
`
