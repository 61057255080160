import React from 'react'
import styled from 'styled-components'

function Content({className, children}) {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export default styled(Content)`
flex-grow: 1;
background: hsla(0,15%,13%,.9);
backdrop-filter: blur(6rem);
border-radius: 0 .33rem .33rem 0;
padding: 2rem;
color: white;
overflow: auto;

a {
    color: hsla(0,25%,85%,1);
    text-decoration: none;
}
`
