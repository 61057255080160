import styled from 'styled-components'
import Button from './Button'

function ListEntry({className, logo, title, description, action}) {
    return (
        <div className={className}>
            { logo &&
                <img className="logo" alt="" src={logo} />
            }
            <div className="content">
                <div className="content--title">{title}</div>
                <div className="content--body">{description}</div>
            </div>
            { action &&
                <Button title={action.name} href={action.href} className="btn" margin="0 0 0 auto" />
            }
        </div>
    )
}

export default styled(ListEntry)`
display: flex;
flex-flow: row nowrap;
align-items: center;

.logo {
    border-radius: .25rem;
    width: 48px;
    height: 48px;
    margin-right: 1rem;
}

.content {
    display: flex;
    flex-flow: column nowrap;

    .content--title {
        margin-bottom: .25rem;
    }

    .content--body {
        opacity: 0.75;
        margin-right: .5rem;
    }
}
`
