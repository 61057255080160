import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

function Button({className, title, href = null, to = null, margin = "0"}) {
    if (href) {
        return <a className={className} href={href} target="_blank" rel="noreferrer">{title}</a>
    } else {
        return <Link className={className} to={to}>{title}</Link>
    }
}

export default styled(Button)`
margin: ${props => props.margin};
display: flex;
justify-content: center;
align-items: center;
font-weight: bold;
color: hsl(210,90%,50%) !important;
background: white;
padding: .25rem 1rem;
border-radius: 99rem 99rem;
text-transform: uppercase;
font-size: .8rem;
`
