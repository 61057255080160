import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

function Sidebar({className}) {
    return (
        <div className={className}>
            <div className="titlebar">
                <div className="btn btn--close" />
                <div className="btn btn--minimize" />
                <div className="btn btn--maximize" />
            </div>
            <section>
                <div className="title">Marco Quinten</div>
                <div className="links">
                    <Link to="/">About</Link>
                    <Link to="/references">References</Link>
                </div>
                <div className="title">Projects</div>
                <div className="links">
                    <Link to="/gpts">GPTs</Link>
                </div>
            </section>
        </div>
    )
}

export default styled(Sidebar)`
width: 20vw;
background: hsla(0,15%,15%,.75);
-webkit-backdrop-filter: blur(4rem);
backdrop-filter: blur(4rem);
border-radius: .33rem 0 0 .33rem;
min-width: 150px;

@media screen and (min-width: 900px) {
    width: 200px;
    min-width: 200px;
}

.titlebar {
    display: flex;
    padding: 0 1rem;
    align-items: center;
    height: 3rem;
    gap: .5rem;

    .btn {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background: white;
        position: relative;
    }

    .btn--close {
        background: hsl(0,90%,65%);
    }

    .btn--minimize {
        background: hsl(45,95%,60%);
    }

    .btn--maximize {
        background: hsl(80,75%,60%);
    }
}

section {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 1rem;
    color: white;
    font-size: .8rem;

    .title {
        margin-bottom: .5rem;
        opacity: .75;
        font-size: 0.7rem;
    }

    .links {
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;
        margin-bottom: .75rem;

        a {
            padding: .5rem .5rem;
            color: white;
            text-decoration: none;

            &:hover {
                border-radius: .25rem;
                background: hsla(0,0%,0%,.25);
            }
        }
    }
}
`
