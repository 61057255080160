import './App.css';
import Sidebar from './components/Sidebar';
import Window from './components/Window';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Content from './components/Content';
import About from './pages/About';
import References from './pages/References';
import GPTs from './pages/GPTs';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Window>
          <Sidebar />
          <Content>
            <Routes>
              <Route path="/" element={<About />} />
              <Route path="/references" element={<References />} />
              <Route path="/gpts" element={<GPTs />} />
            </Routes>
          </Content>
        </Window>
      </BrowserRouter>
    </div>
  )
}

export default App;
