import React from 'react'
import styled from 'styled-components'

function List({className, children}) {
    return (
        <div className={className}>
            {React.Children.map(children, child => (
                <>
                    {child}
                    <div className="list--separator" />
                </>
            ))}
        </div>
    )
}

export default styled(List)`
display: flex;
flex-flow: column nowrap;
overflow: auto;
max-height: 33vh;
padding: 0 1rem;

.list--separator {
    align-self: flex-end;
    ${'' /* width: calc(100% - 48px - 1rem); */}
    width: calc(100% - 1rem);
    min-height: 1.5px;
    height: 100%;
    max-height: 1.5px;
    background: hsla(0,0%,100%,.05);
}

.list--separator:last-child {
    display: none;
}

>:not(:last-child) {
    margin: .5rem 0;
}
`
